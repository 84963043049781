import { useQuery } from '@apollo/client';
import { Typography, styled } from '@mui/material';
import getConfig from 'next/config';
import { useCallback, useEffect, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import modulesConfig from '../../modules/modules';
import { ModuleType, NavigationItemType } from '../../types/module';
import { FETCH_WIDGETS, FetchWidgetsType } from '../api/widgets';
import Dashboard from '../components/Dashboard';
import { useSession } from '../components/SessionProvider';
import useCustomRouter from '../hooks/useCustomRouter';
import WidgetImagesProvider from '../settings/widgets/provider/WidgetImagesProvider';
import { PublicRuntimeConfigType } from '../types/publicRuntimeConfig';

const InfoWrapper = styled('div')(({ theme: { spacing } }) => ({
	height: '100%',
	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	gap: spacing(2),
}));

const Index = () => {
	const { customerFile: sessionCustomerFile, checkPermission } = useSession();
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { standaloneModule } = publicRuntimeConfig;
	const { replace, query } = useCustomRouter();

	const { data: widgetData, loading } = useQuery<FetchWidgetsType>(FETCH_WIDGETS, {
		skip: !!standaloneModule,
	});

	const { widgets } = widgetData ?? {};

	const widgetWithKeys = useMemo(
		() =>
			widgets?.map((widget) => ({
				key: uuid(),
				widget,
			})),
		[widgets],
	);

	const findFirstAccessibleRoute = useCallback(
		(items: (ModuleType | NavigationItemType)[]): string | undefined =>
			items.reduce<string | undefined>((foundRoute, item) => {
				if (foundRoute) {
					return foundRoute;
				}

				if (!(item.permissionCodes?.some((code) => checkPermission(code)) ?? true)) {
					return undefined;
				}

				if (item.subItems && item.subItems.length > 0) {
					return findFirstAccessibleRoute(item.subItems);
				}

				return item.route.slice(1);
			}, undefined),
		[checkPermission],
	);

	const navigateToModule = useCallback(
		({ module: { route, subItems } }: { module: ModuleType }) => {
			const firstAllowedSubItemRoute = subItems ? findFirstAccessibleRoute(subItems) : undefined;

			const newPathnameArray = route
				.split('/')
				.filter((item) => !!item && (standaloneModule ? item !== standaloneModule : true));

			if (firstAllowedSubItemRoute) {
				newPathnameArray.push(firstAllowedSubItemRoute);
			}

			replace({
				pathname: `/${newPathnameArray.join('/')}`,
				query,
			});
		},
		[findFirstAccessibleRoute, query, replace, standaloneModule],
	);

	useEffect(() => {
		if (standaloneModule && !!sessionCustomerFile) {
			const foundStandaloneModule = modulesConfig.find(({ code }) => code === standaloneModule);

			if (foundStandaloneModule) {
				navigateToModule({ module: foundStandaloneModule });
			}
		}
	}, [navigateToModule, sessionCustomerFile, standaloneModule]);

	return (
		<>
			{!sessionCustomerFile && (
				<InfoWrapper>
					<Typography variant="h6">Aucun dossier</Typography>
					<Typography variant="body2">Aucun dossier client n&apos;est disponible</Typography>
				</InfoWrapper>
			)}

			{sessionCustomerFile && !standaloneModule ? (
				<WidgetImagesProvider>
					<Dashboard widgetsWithKey={widgetWithKeys} loading={loading} />
				</WidgetImagesProvider>
			) : null}
		</>
	);
};

export default Index;
