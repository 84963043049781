import { useQuery } from '@apollo/client';
import { ThemeProvider as HarfangThemeProvider, Icon, Layout, Page, Sidebar } from '@elipssolution/harfang';
import { mdiCog, mdiFileSign, mdiLifebuoy } from '@mdi/js';
import { createTheme, Stack, styled, ThemeProvider } from '@mui/material';
import clsx from 'clsx';
import getConfig from 'next/config';
import Image from 'next/image';
import { signOut } from 'next-auth/react';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import AlertBanner from './AlertBanner';
import { useApollo } from './ApolloProvider';
import ImpersonateBanner from './ImpersonateBanner';
import { useLogo } from './LogoProvider';
import { useSession } from './SessionProvider';
import SidebarBody from './SidebarBody';
import SidebarCustomerFileSelector from './SidebarCustomerFileSelector';
import SplashScreen from './SplashScreen';
import SupportDialog from './SupportDialog';
import { CustomerFileType } from '../../types/customerFile';
import { FETCH_THEME, FetchThemeType } from '../api/theme';
import useAlert from '../hooks/useAlert';
import useCustomRouter from '../hooks/useCustomRouter';
import { useModules } from '../hooks/useModules';
import SettingsDialog from '../settings/SettingsDialog';
import { PublicRuntimeConfigType } from '../types/publicRuntimeConfig';
import { UserTypeEnum } from '../types/user';

const StyledLayout = styled(Layout)({
	display: 'flex',
	flexDirection: 'column',
});

const AppLayout = ({ children }: { children: ReactNode }) => {
	const { publicRuntimeConfig } = getConfig() as PublicRuntimeConfigType;
	const { isBrochureHidden } = publicRuntimeConfig;
	const {
		impersonatedUser,
		user: { lastName, firstName, type, hasSettingsAccess },
	} = useSession();
	const { refetchActiveQueries } = useApollo();
	const { query, pathname, replace } = useCustomRouter();
	const { dossier: queryCustomerFileCode } = query || {};

	const { logo, logoCollapsed } = useLogo();
	const modules = useModules();
	const { alert } = useAlert();
	const { content: alertContent, isActive: isAlertActive } = alert ?? {};

	const [logoUrl, setLogoUrl] = useState<string>();
	const [logoCollapsedUrl, setLogoCollapsedUrl] = useState<string>();
	const [isSuggestionsDialogOpen, setIsSuggestionsDialogOpen] = useState(false);

	const openSettings = useCallback(
		() =>
			replace(
				{ query: { ...query, settings: 'true' } },
				`${queryCustomerFileCode ? `?dossier=${queryCustomerFileCode as CustomerFileType['code']}` : ''}`,
			),
		[query, queryCustomerFileCode, replace],
	);

	const closeSettingsDialog = useCallback(() => {
		const { settings, ...restQuery } = query;

		replace(
			{
				query: restQuery,
			},
			`${queryCustomerFileCode ? `?dossier=${queryCustomerFileCode as CustomerFileType['code']}` : ''}`,
		);

		refetchActiveQueries().catch((error) => {
			throw error;
		});
	}, [query, queryCustomerFileCode, refetchActiveQueries, replace]);

	const { data: themeData } = useQuery<FetchThemeType>(FETCH_THEME);

	const themeColors = useMemo(() => themeData?.theme, [themeData]);

	const theme = useMemo(
		() =>
			themeColors
				? createTheme({
						palette: {
							primary: {
								main: themeColors?.primaryColor,
							},
							secondary: {
								main: themeColors?.secondaryColor,
							},
							tertiary: {
								main: themeColors?.tertiaryColor,
							},
						},
				  })
				: undefined,
		[themeColors],
	);

	const openBrochure = () => window.open('https://plaquette.afsolutions.fr', '_blank');

	const openSuggestionsDialog = () => setIsSuggestionsDialogOpen(true);

	// useCallback is needed to avoid rerendering the SuggestionsDialog's function 'handleDialogClose' every 5 seconds
	const closeSuggestionsDialog = useCallback(() => {
		setIsSuggestionsDialogOpen(false);
	}, []);

	useEffect(
		() =>
			setLogoUrl((prev) => {
				if (prev) {
					URL.revokeObjectURL(prev);
				}
				return logo && URL.createObjectURL(logo);
			}),
		[logo],
	);

	useEffect(
		() =>
			setLogoCollapsedUrl((prev) => {
				if (prev) {
					URL.revokeObjectURL(prev);
				}
				return logoCollapsed && URL.createObjectURL(logoCollapsed);
			}),
		[logoCollapsed],
	);

	// FIXME: Only use the ThemeProvider from Harfang when the issue is fixed
	return theme ? (
		<HarfangThemeProvider {...themeColors}>
			<ThemeProvider theme={theme}>
				<StyledLayout className={clsx({ hasBanner: !!impersonatedUser || !!alert })}>
					{impersonatedUser && <ImpersonateBanner impersonatedUser={impersonatedUser} />}
					{isAlertActive && alertContent && <AlertBanner content={alertContent} />}
					<Stack flex={1} direction="row">
						<Sidebar
							logo={
								logoUrl && (
									<Image
										src={logoUrl}
										loader={({ src }) => src}
										alt="logo"
										width={384}
										height={96}
										objectFit="contain"
										unoptimized
									/>
								)
							}
							logoCollapsed={
								logoCollapsedUrl && (
									<Image
										src={logoCollapsedUrl}
										loader={({ src }) => src}
										alt="logo"
										width={96}
										height={96}
										objectFit="contain"
										unoptimized
									/>
								)
							}
						>
							<Sidebar.Header>
								<SidebarCustomerFileSelector />
							</Sidebar.Header>

							<SidebarBody modules={modules} />

							<Sidebar.Footer>
								{type === UserTypeEnum.INTERNAL && isBrochureHidden !== 'true' && (
									<Sidebar.Item icon={<Icon path={mdiFileSign} />} onClick={openBrochure} label="Process Plaquette" />
								)}
								<Sidebar.Item icon={<Icon path={mdiLifebuoy} />} onClick={openSuggestionsDialog} label="Support" />

								{hasSettingsAccess && (
									<Sidebar.Item icon={<Icon path={mdiCog} />} onClick={openSettings} label="Paramétrage" />
								)}

								<Sidebar.ProfileItem
									onLogout={() => {
										signOut({ callbackUrl: '/' }).catch((error) => {
											throw error;
										});
									}}
								>
									{`${firstName ?? ''} ${lastName}`.trim()}
								</Sidebar.ProfileItem>
							</Sidebar.Footer>
						</Sidebar>
						<Page disablePadding={pathname === '/'}>{children}</Page>
					</Stack>
				</StyledLayout>

				{hasSettingsAccess && <SettingsDialog open={!!query.settings} onClose={closeSettingsDialog} />}

				<SupportDialog open={isSuggestionsDialogOpen} onClose={closeSuggestionsDialog} />
			</ThemeProvider>
		</HarfangThemeProvider>
	) : (
		<SplashScreen description="Chargement du thème..." />
	);
};

export default AppLayout;
